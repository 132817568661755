import React, { useEffect, useState } from 'react';
import CoffeeBackground from './Surpriseme_background.js';
import './Surpriseme.css';

const drinks = [
    "Espresso", "Americano", "Cappuccino", "Latte", "Mocha", "Flat White", "Macchiato", "Cortado",
    "Cold Brew", "Iced Coffee", "Nitro Cold Brew", "Affogato", "Chai Latte", "Matcha Latte", 
    "Irish Coffee", "Dalgona Coffee", "Caramel Macchiato", "Pumpkin Spice Latte", 
    "Coconut Milk Latte", "Honey Almondmilk Flat White", "Vanilla Bean Latte", "Peppermint Mocha",
    "Almond Milk Cappuccino", "Dirty Chai Latte", "Turmeric Latte", "Red Eye", "Black Eye", "Breve", 
    "Ristretto", "Long Black", "Vienna Coffee", "Nitro Coffee", "Affogato al Caffè", "Frappe", 
    "Iced Americano", "Café au Lait", "Café con Leche", "Caffè Breve", "Café Cubano", "Piccolo Latte", 
    "Moka Pot Coffee"
];

const getRandomDrink = () => {
    const randomIndex = Math.floor(Math.random() * drinks.length);
    return drinks[randomIndex];
};

function Surprise_Me() {
    const [userLocation, setUserLocation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [surpriseDrink, setSurpriseDrink] = useState(null);
    const [currentCafe, setCurrentCafe] = useState(null);

    const fetchNearbyCafes = async () => {
        if (!userLocation) return;

        const { lat, lng } = userLocation;

        try {
            const response = await fetch(`/api/places?lat=${lat}&lng=${lng}`);
            const data = await response.json();

            if (!response.ok) {
                throw new Error('Failed to fetch nearby cafes');
            }

            const cafes = data.results;
            if (cafes.length > 0) {
                const randomCafeIndex = Math.floor(Math.random() * cafes.length);
                setCurrentCafe(cafes[randomCafeIndex]);
            } else {
                alert("No nearby cafes found.");
            }
        } catch (error) {
            console.error('Error fetching nearby cafes:', error);
        }
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                setLoading(false);
            },
            (error) => {
                console.error("Geolocation Error:", error);
                setLoading(false);
                alert("Could not access your location. Please check your browser settings.");
            }
        );
    }, []);

    useEffect(() => {
        if (userLocation) fetchNearbyCafes();
    }, [userLocation]);

    const getDirectionsLink = () => {
        if (currentCafe && userLocation) {
            const userLatLng = `${userLocation.lat},${userLocation.lng}`;
            const cafeLatLng = `${currentCafe.geometry.location.lat},${currentCafe.geometry.location.lng}`;
            return `https://www.google.com/maps/dir/?api=1&origin=${userLatLng}&destination=${cafeLatLng}`;
        }
        return '';
    };

    const generateNewDrink = () => setSurpriseDrink(getRandomDrink());
    const regenerateCafe = () => fetchNearbyCafes();

    return (
        <>
            <CoffeeBackground />
            <div className="main-content">
                <div className="container">
                    <div className="header">
                        <h1>Surprise Me!</h1>
                    </div>
                    <div className="content">
                        {loading ? (
                            <div className="loading-spinner">Finding a surprise for you...</div>
                        ) : (
                            <div id="display" className="generation-box">
                                {surpriseDrink && currentCafe
                                    ? `How about a ${surpriseDrink} from ${currentCafe.name} located at ${currentCafe.vicinity}?`
                                    : "No surprise found yet."}
                            </div>
                        )}
                        <button onClick={generateNewDrink} className="generate-button">
                            Generate Drink!
                        </button>
                        <button onClick={regenerateCafe} className="generate-button">
                            Regenerate Cafe
                        </button>
                        {currentCafe && (
                            <div className="directions-button">
                                <a href={getDirectionsLink()} target="_blank" rel="noopener noreferrer">
                                    Get Directions to {currentCafe.name}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Surprise_Me;